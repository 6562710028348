import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './Contact.css';
import axios from 'axios';

class Contact extends Component {
    constructor(props){
        super(props);
        this.state = {
            preName: '',
            name: '',
            adress: '',
            city: '',
            phone: '',
            email:'',
            checkbox1:'',
            checkbox2:'',
            checkbox3:'',
            checkbox4:'',
            checkbox5:'',
            comments: ''
            }
        this.handleSubmit = this.handleSubmit.bind(this)
        }


        handlePreNameChange(event){
            this.setState({ preName: event.target.value });
        }
        handleNameChange(event){
            this.setState({ name: event.target.value });
        }
        handleAdressChange(event){
            this.setState({ adress: event.target.value });
        }
        handleCityChange(event){
            this.setState({ city: event.target.value });
        }
        handlePhoneChange(event){
            this.setState({ phone: event.target.value });
        }
        handleEmailChange(event){
            this.setState({ email: event.target.value });
        }
        handleCheckBox1Change(event){
            this.setState({ checkbox1: event.target.checked });
        }
        handleCheckBox2Change(event){
            this.setState({ checkbox2: event.target.checked });
        }
        handleCheckBox3Change(event){
            this.setState({ checkbox3: event.target.checked });
        }
        handleCheckBox4Change(event){
            this.setState({ checkbox4: event.target.checked });
        }
        handleCheckBox5Change(event){
            this.setState({ checkbox5: event.target.checked });
        }
        handleCommentsChange(event){
            this.setState({ comments: event.target.value });
        }


    handleSubmit (event){
        event.preventDefault();
        axios({
            method: "POST",
            url:"http://localhost:3002/send",
            data: this.state
        }).then((response)=>{
      if (response.data.status === 'success'){
        alert("Das Formular wurd abgesendet.");
        this.resetForm()
      }else if(response.data.status === 'fail'){
        alert("Das Formular konnte leider NICHT abgesendet werden.")
      }
    })
  }

  resetForm(){
    this.setState({
        preName: '',
        name: '',
        adress: '',
        city: '',
        phone: '',
        email:'',
        checkbox1:'',
        checkbox2:'',
        checkbox3:'',
        checkbox4:'',
        checkbox5:'',
        comments: ''})
    }

  mapShow() {
          window.open('https://goo.gl/maps/y3nvvfwbDHcQTsUJ6');
      }

  render () {
      const {t} = this.props;
      return (
        <div className="ContactBody">
             <div className="Contact" id='Contact'>
                <h1>Contact</h1>
                <h2>{t('contact.title1')} EDDYBOAT</h2>
                <p>{t('contact.adress1')}</p>
                <p>{t('contact.adress2')}</p>
                <p>2505 Biel/Bienne</p>
                <p><a href="mailto:info@eddyboat.ch">info@eddyboat.ch</a></p>
                <p><a href="www.eddyboat.ch">www.eddyboat.ch</a></p>
                <p> Mobile <a href="tel:+41 79 251 20 07">+41 79 251 20 07</a></p>
                <p> {t('contact.adress3')} <button className="Map" type="button" value="button" onClick={this.mapShow}>&#128506;</button></p>
            </div>
            <div className="ContactForm">
            <form className="infoForm" onSubmit={this.handleSubmit.bind(this)} method="POST">
                <h2>{t('contact.form1')}</h2>
                <p>{t('contact.form2')}</p>
                <label>{t('contact.preName')} </label> <input className="txtbox" type="text" name="preName" required onChange={this.handlePreNameChange.bind(this)} value={this.state.preName}/>
                <label>{t('contact.name')} </label> <input className="txtbox"  type="text" name="name" required onChange={this.handleNameChange.bind(this)} value={this.state.name}/>
                <label>{t('contact.adress')} </label> <input className="txtbox" type="text" name="adress" required onChange={this.handleAdressChange.bind(this)} value={this.state.adress}/>
                <label>{t('contact.city')}</label><input className="txtbox" type="text" name="city" required onChange={this.handleCityChange.bind(this)} value={this.state.city}/>
                <label>{t('contact.phone')} </label><input className="txtbox" type="tel" name="phone" required onChange={this.handlePhoneChange.bind(this)} value={this.state.phone}/>
                <label>Email </label><input className="txtbox" type="email" name="email" required onChange={this.handleEmailChange.bind(this)} value={this.state.email}/>
                <p>{t('contact.form3')}</p>
                <label>{t('contact.form4')} <input className="ckbox" type="checkbox" name="checkbox1" onChange={this.handleCheckBox1Change.bind(this)} value={this.state.checkbox1}/> </label>
                <label>{t('contact.form5')} <input className="ckbox" type="checkbox" name="checkbox2" onChange={this.handleCheckBox2Change.bind(this)} value={this.state.checkbox2}/> </label>
                <label>{t('contact.form6')} <input className="ckbox" type="checkbox" name="checkbox3" onChange={this.handleCheckBox3Change.bind(this)} value={this.state.checkbox3}/> </label>
                <label>{t('contact.form7')} <input className="ckbox" type="checkbox" name="checkbox4" onChange={this.handleCheckBox4Change.bind(this)} value={this.state.checkbox4}/> </label>
                <label>{t('contact.form8')} <input className="ckbox" type="checkbox" name="checkbox5" onChange={this.handleCheckBox5Change.bind(this)} value={this.state.checkbox5}/> </label>
                <label>{t('contact.form9')}</label>
                <textarea rows="5" cols="50" name="comments" onChange={this.handleCommentsChange.bind(this)} value={this.state.comments}/>
                <input className="SubmitButton" type="submit" onClick={this.handleSubmit} />
              </form>
            </div>
        </div>
      )
   }
}
export default withTranslation()(Contact);
