import React, { Component } from 'react';
import './Header.css';
import { withTranslation } from 'react-i18next';
import i18n from "i18next";
import { Link } from "react-router-dom";


class Header extends Component {
  state = {
  }

  render () {
      const {t} = this.props;
      const changeLanguage = (lng) => {
          i18n.changeLanguage(lng);}
      return (
        <div className="header-container">
             <div className="logo">
                <Link to="/">
                    <img src="/Boat-Pics/EB_logo.png" alt="logo"/>
                </Link>
             </div>
             <div className="contact" >
                <p> Eddy Röthlisberger &#9742; <a href="tel:+41 79 251 20 07">+41 79 251 20 07</a> &#9993; <a href="mailto:info@eddyboat.ch">info@eddyboat.ch</a></p>
             </div>
                 <div className="navbar">
                    <ul className="navbarPoints">
                        <li>
                            <Link to="/Home">{t('header.nav1')}</Link>
                        </li>
                        <li>
                            <Link to="/Angebot">{t('header.nav2')}</Link>
                        </li>
                        <li>
                            <Link to="/Preise">{t('header.nav3')}</Link>
                        </li>
                        <li>
                            <Link to="/About">{t('header.nav4')}</Link>
                        </li>
                        <li>
                            <Link to="/Boats">{t('header.nav5')}</Link>
                        </li>
                        <li>
                            <Link to="/Contact">{t('header.nav6')}</Link>
                        </li>
                    </ul>
                 </div>
             <div className="dropdown">
                <button className="dropbtn">{t('header.Lang')}</button>
                <div className="dropdown-content">
                    <button onClick={() => changeLanguage('de')}>DE</button>
                    <button onClick={() => changeLanguage('fr')}>FR</button>
                    <button onClick={() => changeLanguage('en')}>EN</button>
                </div>
             </div>
        </div>
      )
   }
}
export default withTranslation()(Header);
