import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './About.css';

class About extends Component {
   state = {
   }
   render () {
      const {t} = this.props;
      return (
            <div className='AboutBody'>
                <h1>{t('about.title1')}</h1>
                <div className='Coaches'>
                    <div className='Eddy'>
                        <h2>{t('about.title2')}</h2>
                        <img src="/Boat-Pics/Eddy.png" width="60%" alt="PortfolioEddy"/>
                        <ul>
                            <li style={{ listStyleType: "none" }}>{t('about.edparagraph1')}</li>
                            <li style={{ listStyleType: "none" }}>{t('about.edparagraph2')}</li>
                            <li style={{ listStyleType: "none" }}>{t('about.edparagraph3')}</li>
                            <li style={{ listStyleType: "none" }}>{t('about.edparagraph4')}</li>
                            <li style={{ listStyleType: "none" }}>{t('about.edparagraph5')}</li>
                            <li style={{ listStyleType: "none" }}>{t('about.edparagraph6')}</li>
                        </ul>
                    </div>
                    <div className='Ueli'>
                        <h2>{t('about.title3')}</h2>
                        <ul>
                            <li style={{ listStyleType: "none" }}>{t('about.ulparagraph1')}</li>
                            <li style={{ listStyleType: "none" }}>{t('about.ulparagraph2')}</li>
                            <li style={{ listStyleType: "none" }}>{t('about.ulparagraph3')}</li>
                            <li style={{ listStyleType: "none" }}>{t('about.ulparagraph4')}</li>
                            <li style={{ listStyleType: "none" }}>{t('about.ulparagraph5')}</li>
                        </ul>
                    </div>
                </div>
            </div>
      )
   }
}
export default withTranslation()(About);
