import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './Boats.css';


class Boats extends Component {
  state = {
  }


  render () {
      const {t} = this.props;
      return (
        <div className="BoatsBody">
             <div className="BMotorboot">
                <h1>{t('boats.title1')}</h1>
                <img src="/Boat-Pics/BW.jpg" width="60%" alt="BostonWhaler"/>
                <ul>
                    <li  style={{ listStyleType: "none" }}>{t('boats.mbparagraph1')}</li>
                    <li  style={{ listStyleType: "none" }}>{t('boats.mbparagraph2')}</li>
                    <li  style={{ listStyleType: "none" }}>{t('boats.mbparagraph3')}</li>
                    <li  style={{ listStyleType: "none" }}>{t('boats.mbparagraph4')}</li>
                </ul>
            </div>
            <div className="BSegelboot">
               <h1>{t('boats.title2')}</h1>
               <img src="/Boat-Pics/j70.jpg"  width="60%" alt="J70"/>
               <ul>
                   <li  style={{ listStyleType: "none" }}>{t('boats.sbparagraph1')}</li>
                   <li  style={{ listStyleType: "none" }}>{t('boats.sbparagraph2')}</li>
                   <li  style={{ listStyleType: "none" }}>{t('boats.sbparagraph3')}</li>
                   <li  style={{ listStyleType: "none" }}>{t('boats.sbparagraph4')}</li>
               </ul>
            </div>
        </div>
      )
   }
}
export default withTranslation()(Boats);
