import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './Angebot.css'

class Angebot extends Component {
   state = {
   }
   render () {
      const {t} = this.props;
      return (
            <div className='angebotBody'>
                <div className='MKurse'>
                    <h1>{t('angebot.title1')}</h1>
                    <ul className="Motorbootliste">
                        <li>{t('angebot.mbpoint1')}</li>
                        <li>{t('angebot.mbpoint2')}</li>
                        <li>{t('angebot.mbpoint3')}</li>
                        <li>{t('angebot.mbpoint4')}</li>
                    </ul>
                </div>
                <div className='SKurse'>
                    <h1>{t('angebot.title2')}</h1>
                    <ul className="Segelbootliste">
                        <li>{t('angebot.sbpoint1')}</li>
                        <li>{t('angebot.sbpoint2')}</li>
                        <li>{t('angebot.sbpoint3')}</li>
                        <li>{t('angebot.sbpoint4')}</li>
                        <li>{t('angebot.sbpoint5')}</li>
                        <li>{t('angebot.sbpoint6')}</li>
                        <li>{t('angebot.sbpoint7')}</li>
                    </ul>
                </div>
                <div className='Diverses'>
                    <h1>{t('angebot.title3')}</h1>
                    <ul className="Motorbootliste">
                        <li>{t('angebot.divpoint1')}</li>
                        <li>{t('angebot.divpoint2')}</li>
                        <li>{t('angebot.divpoint3')}</li>
                    </ul>
                </div>
            </div>
      )
   }
}
export default withTranslation()(Angebot);
