import React, { Component } from 'react';
import './Footer.css';
import { SocialIcon } from 'react-social-icons';


export default class Footer extends Component {
  state = {
  }

  render () {
      return (
        <div className="footer-container">
             <div className='Logos'>
                <SocialIcon url='http://facebook.com'/>
             </div>
             <div className='Contact'>
                <p> Eddy Röthlisberger &#9742; <a href="tel:+41 79 251 20 07">+41 79 251 20 07</a> &#9993; <a href="mailto:info@eddyboat.ch">info@eddyboat.ch</a></p>
             </div>
        </div>
      )
   }
}
