import React, { Component } from 'react';
import './Preise.css';
import { withTranslation } from 'react-i18next';


class Preise extends Component {
  state = {
  }


  render () {
      const {t} = this.props;
      return (
        <div className="PreiseBody">
             <div className="PMotorbootliste ">
             <h1>{t('preise.title1')}</h1>
                <div className="CourseDescr">
                <ul>
                    <li>{t('preise.kurspreis1')}</li>
                    <li>{t('preise.kurspreis2')}</li>
                    <li>{t('preise.kurspreis3')}</li>
                </ul>
                </div>
                <div className="CoursePrice">
                <ul>
                    <li style={{ listStyleType: "none" }}>1h 100 CHF</li>
                    <li style={{ listStyleType: "none" }}>1h 100 CHF (50 CHF / Person)</li>
                    <li style={{ listStyleType: "none" }}>10h 900 CHF</li>
                </ul>
                </div>
            </div>
            <div className='PSegelliste'>
                <h1>{t('preise.title2')}</h1>
                <div className="CourseDescr">
                    <ul>
                        <li>{t('preise.kurspreis1')}</li>
                        <li>{t('preise.kurspreis2')}</li>
                        <li>{t('preise.kurspreis3')}</li>
                     </ul>
                 </div>
                 <div className="CoursePrice">
                     <ul>
                         <li style={{ listStyleType: "none" }}>1h 100 CHF</li>
                         <li style={{ listStyleType: "none" }}>1h 100 CHF (50 CHF / Person)</li>
                         <li style={{ listStyleType: "none" }}>10h 900 CHF</li>
                     </ul>
                 </div>
           </div>
           <div className='Kombiliste'>
                <h1>{t('preise.title3')}</h1>
                <div className="CourseDescr">
                    <ul>
                        <li>{t('preise.kurspreis1')} </li>
                        <li>{t('preise.kurspreis2')} </li>
                    </ul>
                </div>
                <div className="CoursePrice">
                    <ul>
                        <li style={{ listStyleType: "none" }}>1h 90 CHF</li>
                        <li style={{ listStyleType: "none" }}>1h 90 CHF</li>
                    </ul>
                </div>
           </div>
           <div className="Indliste">
                <h1>{t('preise.title4')}</h1>
                <div className="CourseDescr">
                    <ul>
                        <li>{t('preise.indpreis1')} </li>
                    </ul>
                </div>
                <div className="CoursePrice">
                    <ul>
                        <li style={{ listStyleType: "none" }}>{t('preise.indtext1')}</li>
                    </ul>
                </div>
           </div>
           <div className='Examprice'>
                <h1>{t('preise.title5')}</h1>
                <p>{t('preise.expreis1')} :    50 CHF</p>
                <p>{t('preise.indtext2')}</p>
           </div>
        </div>
      )
   }
}
export default withTranslation()(Preise);
