import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translation_de from './locales/de/translation_de.json';
import translation_fr from './locales/fr/translation_fr.json';
import translation_en from './locales/en/translation_en.json';

// the translations
const resources = {
  de: {
    translation: translation_de
    },
  fr: {
      translation: translation_fr
  },
  en: {
      translation: translation_en
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
     react: {
         useSuspense: false
     },
    resources,
    lng: "de",
    fallbackLng: "de",

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
