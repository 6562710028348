import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Slider from 'infinite-react-carousel'
import './Home.css';


class Home extends Component {
   state = {
   }

render () {
    const {t} = this.props;
    const settings = {
        dots: true,
       duration: 100,
       arrows: false,
       arrowsBlock: false,
       autoplay: true
    }
      return (
            <div className='body'>
                <div className='HomeTitle'>
                    <h2>{t('home.title')}</h2>
                    <p>{t('home.paragraph')}</p>
                </div>
                <div className="gallery">
                    <Slider { ...settings} >
                        <div>
                            <img src={"/Boat-Pics/sail1.jpg"} width="60%" alt="sail1" />
                        </div>
                        <div>
                            <img src={"/Boat-Pics/motor1.jpg"} width="60%" alt="motor1" />
                        </div>
                        <div>
                            <img src={"/Boat-Pics/sail2.jpg"} width="60%" alt="sail2" />
                        </div>
                        <div>
                            <img src={"/Boat-Pics/motor2.jpg"} width="60%" alt="motor2" />
                        </div>
                        <div>
                            <img src={"/Boat-Pics/sail3.jpg"} width="60%" alt="sail3" />
                        </div>
                        <div>
                            <img src={"/Boat-Pics/motor3.jpg"} width="60%" alt="motor3" />
                        </div>
                        <div>
                            <img src={"/Boat-Pics/sail4.jpg"} width="60%" alt="sail4" />
                        </div>
                        <div>
                            <img src={"/Boat-Pics/motor4.jpg"} width="60%" alt="motor4" />
                        </div>
                        <div>
                            <img src={"/Boat-Pics/sail5.jpg"} width="60%"alt="sail5" />
                        </div>
                    </Slider>
                </div>
            </div>
      )
   }
};
export default withTranslation()(Home);
