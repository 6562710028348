import React, { Component } from 'react';
import './App.css';
import Home from './Components/Home';
import Angebot from './Components/Angebot';
import About from './Components/About';
import Preise from './Components/Preise';
import Boats from './Components/Boats';
import Contact from './Components/Contact';
import Header from './Components/Header'
import Footer from './Components/Footer'
import {BrowserRouter, Route, Switch, withRouter} from 'react-router-dom';
import { withTranslation} from 'react-i18next';




class App extends Component {
    render(){
      return (
          <div className="container">
            <BrowserRouter>
                <div className="Header">
                    <Header/>
                </div>
                <div className="Main">
                          <div>
                            <Switch>
                                <Route exact path='/' component={withRouter(Home)}/>
                                <Route exact path='/Home' component={withRouter(Home)}/>
                                <Route exact path='/Angebot' component={withRouter(Angebot)}/>
                                <Route exact path='/About' component={withRouter(About)}/>
                                <Route exact path='/Preise' component={withRouter(Preise)}/>
                                <Route exact path='/Boats' component={withRouter(Boats)}/>
                                <Route exact path='/Contact' component={withRouter(Contact)}/>
                            </Switch>
                         </div>
                 </div>
                 <div className="Footer">
                    <Footer/>
                </div>
            </BrowserRouter>
        </div>
      );
    }
}
export default withTranslation()(App);
